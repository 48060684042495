import * as React from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import SaveIcon from "@mui/icons-material/Save";
import CancelIcon from "@mui/icons-material/Close";
import {
  GridRowsProp,
  GridRowModesModel,
  GridRowModes,
  DataGrid,
  GridColDef,
  GridToolbarContainer,
  GridActionsCellItem,
  GridEventListener,
  GridRowId,
  GridRowModel,
  GridRowEditStopReasons,
  GridToolbarColumnsButton,
  GridToolbarFilterButton,
  GridToolbarDensitySelector,
  GridToolbarExport,
} from "@mui/x-data-grid";
import {
  randomCreatedDate,
  randomTraderName,
  randomId,
  randomArrayItem,
} from "@mui/x-data-grid-generator";
import { Container } from "@mui/material";
import zipcodeServiceManager from "services/zipcode/zipcodeServiceManager";
import apiService from "services/apiService";
import VisibilityIcon from "@mui/icons-material/Visibility";
import { useNavigate } from "react-router-dom";
import CustomPagination from "./CustomPagination";

const roles = ["Market", "Finance", "Development"];
const randomRole = () => {
  return randomArrayItem(roles);
};

const initialRows: GridRowsProp = [];

interface EditToolbarProps {
  setRows: (newRows: (oldRows: GridRowsProp) => GridRowsProp) => void;
  setRowModesModel: (
    newModel: (oldModel: GridRowModesModel) => GridRowModesModel
  ) => void;
}

function EditToolbar(props: EditToolbarProps) {
  const { setRows, setRowModesModel } = props;

  const handleClick = () => {
    const id = randomId();
    setRows((oldRows) => [...oldRows, { id, isNew: true }]);
    setRowModesModel((oldModel) => ({
      ...oldModel,
      [id]: { mode: GridRowModes.Edit, fieldToFocus: "name" },
    }));
  };

  return (
    <GridToolbarContainer>
      <Button color="primary" startIcon={<AddIcon />} onClick={handleClick}>
        Add record
      </Button>
      <GridToolbarColumnsButton />
      <GridToolbarFilterButton />
      <GridToolbarDensitySelector />
      <GridToolbarExport />
    </GridToolbarContainer>
  );
}

export default function FullFeaturedCrudGrid() {
  const [rows, setRows] = React.useState(initialRows);
  const [rowModesModel, setRowModesModel] = React.useState<GridRowModesModel>(
    {}
  );
  const [paginationModel, setPaginationModel] = React.useState({
    pageSize: 10,
    page: 1,
    totalRows: 23120,
  });
  const navigate = useNavigate();

  const [totalPages, setTotalPages] = React.useState(0);
  const [isLoadingNextPage, setIsLoadingNextPage] =
    React.useState<boolean>(true);

  const handleRowEditStop: GridEventListener<"rowEditStop"> = (
    params,
    event
  ) => {
    if (params.reason === GridRowEditStopReasons.rowFocusOut) {
      event.defaultMuiPrevented = true;
    }
  };

  const handleEditClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };

  const handleSaveClick = (id: GridRowId) => () => {
    setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.View } });
  };
  const handleViewClick = (id: any) => () => {
    const row = rows.find((row) => row.id === id);
    if (row) {
      navigate(`/imagegallery/${row.zipcode}`);
    }
    // setRowModesModel({ ...rowModesModel, [id]: { mode: GridRowModes.Edit } });
  };
  const handleDeleteClick = (id: GridRowId) => () => {
    apiService
      .delete(`/api/zipcode/${id}`)
      .then((res) => {
        console.log("res deleted :>> ", res);
      })
      .catch((err) => {
        console.log("err on delete", err);
      });
    setRows(rows.filter((row) => row.id !== id));
  };

  const handleCancelClick = (id: GridRowId) => () => {
    setRowModesModel({
      ...rowModesModel,
      [id]: { mode: GridRowModes.View, ignoreModifications: true },
    });

    const editedRow = rows.find((row) => row.id === id);
    if (editedRow!.isNew) {
      setRows(rows.filter((row) => row.id !== id));
    }
  };

  const processRowUpdate = (newRow: GridRowModel) => {
    const updatedRow = { ...newRow, isNew: false };
    if (newRow.isNew) {
      apiService
        .post(`/api/zipcode`, newRow)
        .then((res) => {
          console.log("res created :>> ", res);
        })
        .catch((err) => {
          console.log("err on create", err);
        });
    } else {
      apiService
        .put(`/api/zipcode/${newRow.id}`, newRow)
        .then((res) => {
          console.log("res updated :>> ", res);
        })
        .catch((err) => {
          console.log("err on Update", err);
        });
    }
    setRows(rows.map((row) => (row.id === newRow.id ? updatedRow : row)));
    return updatedRow;
  };

  const handleRowModesModelChange = (newRowModesModel: GridRowModesModel) => {
    setRowModesModel(newRowModesModel);
  };

  const columns: GridColDef[] = [
    {
      field: "zipcode",
      headerName: "ZipCode",
      type: "number",
      align: "left",
      headerAlign: "left",
      editable: true,
    },

    {
      field: "town",
      headerName: "Town",
      editable: true,
      width: 150,
    },
    {
      field: "city",
      headerName: "City",
      editable: true,
      width: 150,
    },

    {
      field: "state",
      headerName: "State",
      editable: true,
      width: 150,
    },
    {
      field: "stateCode",
      headerName: "State Code",
      editable: true,
    },
    {
      field: "tire",
      headerName: "Tier",
      editable: true,
    },
    {
      field: "county",
      headerName: "County",
      editable: true,
      width: 180,
    },
    {
      field: "actions",
      type: "actions",
      headerName: "Actions",
      width: 100,
      cellClassName: "actions",
      getActions: ({ id, zipcode }: any) => {
        const isInEditMode = rowModesModel[id]?.mode === GridRowModes.Edit;

        if (isInEditMode) {
          return [
            <GridActionsCellItem
              icon={<SaveIcon />}
              label="Save"
              sx={{
                color: "primary.main",
              }}
              onClick={handleSaveClick(id)}
            />,
            <GridActionsCellItem
              icon={<CancelIcon />}
              label="Cancel"
              className="textPrimary"
              onClick={handleCancelClick(id)}
              color="inherit"
            />,
          ];
        }

        return [
          <GridActionsCellItem
            icon={<VisibilityIcon />}
            label="View"
            className="textPrimary"
            onClick={handleViewClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<EditIcon />}
            label="Edit"
            className="textPrimary"
            onClick={handleEditClick(id)}
            color="inherit"
          />,
          <GridActionsCellItem
            icon={<DeleteIcon />}
            label="Delete"
            onClick={handleDeleteClick(id)}
            color="inherit"
          />,
        ];
      },
    },
  ];

  React.useEffect(() => {
    setIsLoadingNextPage(true);
    apiService
      .get(
        `/api/zipcode/all?page=${paginationModel.page}&size=${paginationModel.pageSize}`
      )
      .then((res) => {
        console.log("res", res);
        setIsLoadingNextPage(false);
        setRows(res.data.content);
        // setRows([...rows, ...res.data.content]);
        setTotalPages(res.data.totalPages);
        setPaginationModel((prevModel) => ({
          ...prevModel,
          // totalRows: res.data.totalElements,
        }));
      })
      .catch((err) => {
        console.log("err :>> ", err);
      });
  }, [paginationModel.page, paginationModel.pageSize]);
  const handlePaginationChange = (page: number) => {
    setPaginationModel((prevModel) => ({ ...prevModel, page }));
  };

  const handlePageSizeChange = (pageSize: number) => {
    setPaginationModel((prevModel) => ({ ...prevModel, pageSize, page: 1 }));
  };

  return (
    <Container maxWidth="xl">
      <DataGrid
        loading={isLoadingNextPage}
        rows={rows}
        columns={columns}
        editMode="row"
        rowModesModel={rowModesModel}
        onRowModesModelChange={handleRowModesModelChange}
        onRowEditStop={handleRowEditStop}
        processRowUpdate={processRowUpdate}
        slots={{
          toolbar: EditToolbar,
        }}
        slotProps={{
          toolbar: { setRows, setRowModesModel },
        }}
        // initialState={{
        //   pagination: {
        //     paginationModel: { pageSize: paginationModel.pageSize, page: paginationModel.page+1 },
        //   },
        // }}
        // rowCount={paginationModel.totalRows}
        // paginationModel={paginationModel}
        // onPaginationModelChange={(newModel) =>
        //   setPaginationModel((prevModel) => ({
        //     ...prevModel,
        //     page: newModel.page,
        //     pageSize: newModel.pageSize,
        //   }))
        // }
        // pageSizeOptions={[10, 25, 50]}
      />
      <CustomPagination
        totalPages={totalPages}
        currentPage={paginationModel.page}
        onChangePage={handlePaginationChange}
        onChangePageSize={handlePageSizeChange}
        pageSize={paginationModel.pageSize}
      />
    </Container>
  );
}
