import InboxIcon from "@mui/icons-material/MoveToInbox";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
const sideBarItems = [
  {
    name: "Zipcodes",
    icon: InboxIcon,
    url: "",
  },
  {
    name: "Upload Image",
    icon: CloudUploadIcon,
    url: "upload",
  },
];

export default sideBarItems;
