import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import FullFeaturedCrudGrid from "components/DataTable";

export default function ZipCode() {
  return (
    <>
      <Container maxWidth="lg">
        <FullFeaturedCrudGrid />
      </Container>
    </>
  );
}
