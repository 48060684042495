import React, { useEffect, useState } from "react";

import {
  Badge,
  IconButton,
  ImageListItem,
  ImageListItemBar,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField,
  FormControl,
  MenuItem,
  InputLabel,
  Select,
} from "@mui/material";
import { ImageData } from "./ImageGallery";
import SvgIconComponent from "./SvgComponent";
import SyncIcon from "@mui/icons-material/Sync";

const seasons = ["SPRING", "SUMMER", "AUTUMN", "WINTER"];
const daytimes = ["MORNING", "NOON", "EVENING", "NIGHT"];

const ImageItem = ({
  image,
  index,
  zipcode,
}: {
  image: ImageData;
  index: number;
  zipcode: string;
}) => {
  const [imageInfo, setImageInfo] = useState<ImageData>({} as ImageData);
  const [loading, setLoading] = React.useState(true);
  const [openModal, setOpenModal] = useState(false);
  const [formData, setFormData] = useState<any>(null);
  const [selectedSeason, setSelectedSeason] = useState<string>("");
  const [selectedDaytime, setSelectedDaytime] = useState<string>("");
  const [selectedZipCode, setSelectedZipCode] = useState<string>("");

  function handleClick() {
    setLoading(true);
  }

  useEffect(() => {
    setImageInfo(image);
  }, [image]);

  const handleVerifyClick = (iamgee: ImageData) => {
    // setImageInfo((prevImageInfo) => ({
    //   ...prevImageInfo,
    //   season: "moning here",
    // }));
  };

  const handleCloseModal = () => {
    setOpenModal(false);
  };

  const handleOpenModal = () => {
    setSelectedSeason(imageInfo.season);
    setSelectedDaytime(imageInfo.daytime);
    setSelectedZipCode(zipcode);
    setOpenModal(true);
  };
  const handleGenerateClick = () => {
    // Capture and process the form data
    console.log("Captured data:", formData);

    // Your API call logic here
    console.log("API call to generate");
    // handleCloseModal();
  };
  return (
    <>
      <ImageListItem key={index}>
        <Badge
          overlap="circular"
          style={{ top: "60px", right: "0px" }}
          anchorOrigin={{
            vertical: "top",
            horizontal: "right",
          }}
          badgeContent={
            <span
              style={{
                background: "transparent",
                borderRadius: "50%",
                cursor: "pointer",
              }}
              onClick={() => handleVerifyClick(image)}
            >
              {/*
               this is for unverified 
              
              <SvgIconComponent
                fillColor="gray"
                opacity={0.1}
                backgroundFill="white"
                onClick={() => handleVerifyClick(image)}
              /> */}

              <SvgIconComponent onClick={() => handleVerifyClick(image)} />
            </span>
          }
        ></Badge>

        <img
          alt={`${imageInfo.season}_${imageInfo.daytime}`}
          srcSet={`${imageInfo.imageUrl}`}
          src={`${imageInfo.imageUrl}`}
          loading="lazy"
          height={512}
          width={512}
        />
        <ImageListItemBar
          position="bottom"
          title={`${imageInfo.season} - ${imageInfo.daytime}`}
          actionIcon={
            <IconButton
              sx={{ color: "rgba(255, 255, 255, 0.54)" }}
              aria-label="info"
              onClick={handleOpenModal}
            >
              <SyncIcon />
            </IconButton>
          }
        />
      </ImageListItem>

      {/*  modal data added */}

      <Dialog
        open={openModal}
        onClose={handleCloseModal}
        maxWidth="md"
        sx={{
          "& .MuiDialog-paper": {
            width: "500px", // Adjust the width as needed
            maxHeight: "100vh", // Adjust the max height as needed
          },
        }}
      >
        <DialogTitle>Regenerate Image</DialogTitle>
        <DialogContent>
          <TextField
            sx={{ mb: 2 }}
            autoFocus
            margin="dense"
            id="zipcode"
            label="zipcode"
            type="text"
            fullWidth
            value={selectedZipCode}
            onChange={(e) => setSelectedZipCode(e.target.value)}
            autoComplete="off"
          />
          <FormControl fullWidth sx={{ mb: 2, mt: 2 }}>
            <InputLabel id="season-label">Season</InputLabel>
            <Select
              labelId="season-label"
              id="season-select"
              value={selectedSeason}
              onChange={(e) => setSelectedSeason(e.target.value as string)}
              label="Season"
            >
              {seasons.map((season) => (
                <MenuItem key={season} value={season}>
                  {season}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="daytime-label">Daytime</InputLabel>
            <Select
              labelId="daytime-label"
              id="daytime-select"
              value={selectedDaytime}
              onChange={(e) => setSelectedDaytime(e.target.value as string)}
              label="Daytime"
            >
              {daytimes.map((daytime) => (
                <MenuItem key={daytime} value={daytime}>
                  {daytime}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
          <TextField
            sx={{ mb: 2 }}
            autoFocus
            margin="dense"
            id="name"
            label="Additional Data"
            type="text"
            fullWidth
            onChange={(e) => setFormData(e.target.value)}
            autoComplete="off"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseModal} variant="outlined" color="error">
            Cancel
          </Button>
          <Button onClick={handleGenerateClick} variant="outlined">
            Generate
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ImageItem;
