import React from "react";
import Pagination from "@mui/material/Pagination";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import { SelectChangeEvent } from "@mui/material";

interface CustomPaginationProps {
  totalPages: number;
  currentPage: number;
  onChangePage: (page: number) => void;
  onChangePageSize: (pageSize: number) => void;
  pageSize: number;
}

const CustomPagination: React.FC<CustomPaginationProps> = ({
  totalPages,
  currentPage,
  onChangePage,
  onChangePageSize,
  pageSize,
}) => {
  const handleChangePage = (event: React.ChangeEvent<unknown>, value: number) => {
    onChangePage(value);
  };

  const handleChangePageSize = (event: any) => {
    onChangePageSize(Number(event.target.value));
  };

  return (
    <div className="between">
      <div style={{ display: "flex", alignItems: "center", marginTop: "10px" }}>
        <p style={{ marginRight: "6px" }}>Rows per page :</p>
        <FormControl variant="standard">
          <Select
            value={pageSize}
            onChange={handleChangePageSize}
            inputProps={{
              name: "rows-per-page",
              id: "rows-per-page",
            }}
          >
            <MenuItem value={10}>10</MenuItem>
            <MenuItem value={25}>25</MenuItem>
            <MenuItem value={50}>50</MenuItem>
          </Select>
        </FormControl>
      </div>
      <Pagination
        count={totalPages}
        page={currentPage}
        onChange={handleChangePage}
        siblingCount={1}
        boundaryCount={1}
        variant="outlined"
        // shape="rounded"
        color="primary"
      />

      <style>
        {`
          .MuiPaginationItem-page.Mui-selected {
            background-color: rgb(25, 118, 210);
            color: #fff; /* Set your desired text color */
          }
        `}
      </style>
    </div>
  );
};

export default CustomPagination;
