import { Link } from "react-router-dom";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import React from "react";

const NotFound = () => {
  return (
    <div className="pagenotfoundcontainer" data-testid="404">
      <Typography variant="h3" align="center">
        404 - Page Not Found
      </Typography>
      <Typography variant="body1" align="center">
        The page you are looking for does not exist.
      </Typography>
      <div style={{ textAlign: "center", marginTop: "20px" }}>
        <Button variant="contained" color="primary" component={Link} to="/">
          Go to Home
        </Button>
      </div>
    </div>
  );
};

export default NotFound;
