class AppConfig {
  constructor() {
    console.log("App config --->");
  }
  //testing url
  // https://genai-img.lirisoft.net/
  //   https://manoj.lirisoft.net/s
  //   API_URL = "https://manoj.lirisoft.net/";
  API_URL = "https://genai-img.lirisoft.net";
  getBaseUrl(): string {
    const baseUrl = process.env.REACT_APP_BASE_URL || this.API_URL;
    return baseUrl;
  }
}

const appConfig = new AppConfig();

export default appConfig;
