import NotFound from "pages/404";
import ZipCode from "pages/ZipCode";
import React from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import AdminLayout from "../layouts/AdminLayout";
import DefaultLayout from "../layouts/DefaultLayout";
import ImageGalleryPage from "pages/ImageGalleryPage";
import UploadImage from "pages/UploadImage";
import PrivateRoute from "./PrivateRoute";
import Login from "components/Login";
export default function Routers() {
  return (
    <Router>
      <Routes>
        {/* <Route element={<DefaultLayout />}></Route> */}
        <Route path="/login" element={<Login />} />
        <Route path="/" element={<PrivateRoute Layout={AdminLayout} />}>
          <Route path="/" index element={<ZipCode />} />
          <Route path="/zipcodes" index element={<ZipCode />} />
          <Route path="imagegallery/:zipcode" element={<ImageGalleryPage />} />
          <Route path="/upload" index element={<UploadImage />} />
          <Route path="*" element={<NotFound />} />
        </Route>
        <Route path="*" element={<NotFound />} />
      </Routes>
    </Router>
  );
}
