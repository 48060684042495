import * as React from "react";
// import ImageList from "@mui/material/ImageList";
// import ImageListItem from "@mui/material/ImageListItem";
import {
  Alert,
  Button,
  CardActions,
  CardContent,
  CircularProgress,
  Container,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  ListSubheader,
  Typography,
} from "@mui/material";
import IconButton from "@mui/material/IconButton";
import InfoIcon from "@mui/icons-material/Info";
import imageServiceManager from "services/gallery/ImageServiceManager";
import UseZipCodeInfo from "hooks/UseSearchZipcode";
import UseImageSearchByZipCode from "hooks/UseImageSearchByZipCode";
import Loading from "./Loading";
import ImageItem from "./ImageListItem";

export type zipcodeProps = {
  zipcode: string;
};
export interface ImageData {
  imageUrl: string;
  season: string;
  createTime: string;
  expiryTime: string;
  daytime: string;
}

export interface ApiResponse {
  zipcode: string;
  images: ImageData[];
}
export default function ImageGallery({ zipcode }: zipcodeProps) {
  // const seasons = ["SPRING", "SUMMER", "AUTUMN", "WINTER"];
  // const daytimes = ["MORNING", "NOON", "EVENING", "NIGHT"];

  const seasons = ["SUMMER", "WINTER"];
  const daytimes = ["MORNING", "EVENING"];
  const { zipCodeInfo, setZipCodeInfo } = UseZipCodeInfo(zipcode);
  const { data, setData, isLoading, setIsLoading, isImages, setIsImages } =
    UseImageSearchByZipCode(zipcode);

  const [messageStatus, setMessageStatus] =
    React.useState<string>("Loading...");
  const fetchData = async () => {
    setIsLoading(true);
    for (const season of seasons) {
      for (const daytime of daytimes) {
        console.log(daytime, season);
        setMessageStatus(
          `Generating ${capitalizeFirstLetter(season)} ${capitalizeFirstLetter(
            daytime
          )} Images `
        );
        const requstData = {
          zipcode,
          season,
          daytime,
        };
        try {
          const result = await imageServiceManager.generateImages(requstData);
          const newImage = result.data.images; // Assuming you only want to add the first image from each fetch
          const imageData = [...data, ...newImage];
          setData(imageData); // Add the new image to the existing data
          setIsImages(true); // Set isImages to true to indicate that images are available
        } catch (error) {
          console.log(error);
        }
      }
    }
    setIsLoading(false); // Set isLoading to false once all images are fetched
  };

  const capitalizeFirstLetter = (string: string) => {
    return string.charAt(0).toUpperCase() + string.slice(1).toLocaleLowerCase();
  };
  return (
    <>
      <Container>
        {}
        {zipCodeInfo && (
          <>
            <Typography variant="subtitle1" gutterBottom>
              {zipCodeInfo.city} {""} {""} {zipCodeInfo.town} {""},
              <strong>{zipCodeInfo.state} </strong>
              {""}
              {zipCodeInfo.county}
              {""} <strong> {zipCodeInfo.zipcode} </strong>
            </Typography>
          </>
        )}

        {isLoading && <Loading message={messageStatus} />}

        {!isImages && !isLoading && (
          <>
            <CardContent>
              <Typography color="text.secondary" gutterBottom>
                <Alert severity="error">
                  No images found, please click on the "Generate" button to
                  create the images.
                </Alert>
              </Typography>
            </CardContent>
            <CardActions>
              <Button variant="outlined" onClick={fetchData}>
                Generate Images
              </Button>
            </CardActions>
          </>
        )}
        {data && (
          <ImageList
            cols={2}
            rowHeight="auto"
            style={{ alignItems: "flex-start" }}
          >
            {data.map((image: ImageData, index: number) => (
              <ImageItem
                image={image}
                index={index}
                key={image.imageUrl}
                zipcode={zipcode}
              />
            ))}
          </ImageList>
        )}
      </Container>
    </>
  );
}
