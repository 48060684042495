import {
  Typography,
  Stack,
  Box,
  Avatar,
  Divider,
  Menu,
  MenuItem,
} from "@mui/material";
import React, { useState } from "react";
import { Link as RouterLink, useNavigate } from "react-router-dom";
import PermIdentityIcon from "@mui/icons-material/PermIdentity";
import HomeIcon from "@mui/icons-material/Home";
import LogoutIcon from "@mui/icons-material/Logout";
import PersonIcon from "@mui/icons-material/Person";

const MENU_OPTIONS = [
  {
    label: "Home",
    linkTo: "/",
    icon: HomeIcon,
  },
  {
    label: "Profile",
    icon: PersonIcon,
    linkTo: "admin/profile",
  },
];
function AdminLayoutHeader() {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const navigate = useNavigate();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleLogout = async () => {
    try {
      sessionStorage.clear();
      localStorage.clear();
      navigate("/login", { replace: true });
    } catch (error) {
      console.error(error);
    }
  };

  return (
    <div data-testid="admin-header" style={{ display: "contents" }}>
      <Typography
        variant="h5"
        noWrap
        color="secondary"
        component="div"
        style={{ fontWeight: 600 }}
      >
        Verizon GenAI Image Generator
      </Typography>
      <Box sx={{ flexGrow: 1 }} />
    </div>
  );
}

export default React.memo(AdminLayoutHeader);
