import { AxiosResponse } from "axios";
import { zipCodeResponse, zipCodeItem } from "./zipcodeIntreface";
import apiService from "services/apiService";

class ZipcodeServiceManager {
  private url: string;
  constructor() {
    this.url = `/api/zipcode`;
  }

  async getAllZipCodeData(): Promise<AxiosResponse<zipCodeResponse>> {
    return await apiService.get(`${this.url}/all`);
  }

  async addZipCode(formData: FormData): Promise<AxiosResponse<zipCodeItem>> {
    return await apiService.post(this.url, formData);
  }

  async getZipCode(id: string): Promise<AxiosResponse<zipCodeItem>> {
    return await apiService.get(`${this.url}/${id}`);
  }
  async updateZipCode(formData: FormData): Promise<AxiosResponse<zipCodeItem>> {
    return await apiService.put(this.url, formData);
  }

  async deleteZipCode(id: number): Promise<AxiosResponse> {
    return await apiService.delete(`${this.url}/${id}`);
  }
}

const zipcodeServiceManager = new ZipcodeServiceManager();
export default zipcodeServiceManager;
