import React, { Suspense } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import Routers from "./Routes/Routers";
import ThemeWrapper from "themes/ThemeProvider";
import ErrorBoundary from "components/ErrorBoundary";

function Loading() {
  return <CircularProgress />;
}
export default function App() {
  if (process.env.NODE_ENV === "production") {
    if (console) {
      console.log = function () {};
    }
  }
  return (
    <ErrorBoundary>
      <Suspense fallback={<Loading />}>
        <ThemeWrapper>
          <Routers />
        </ThemeWrapper>
      </Suspense>
    </ErrorBoundary>
  );
}
