// AdminLayout.js
import * as React from "react";
import { styled, useTheme, Theme, CSSObject } from "@mui/material/styles";
import { Box, Collapse } from "@mui/material";
import MuiDrawer from "@mui/material/Drawer";
import MuiAppBar, { AppBarProps as MuiAppBarProps } from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import List from "@mui/material/List";
import CssBaseline from "@mui/material/CssBaseline";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import MenuIcon from "@mui/icons-material/Menu";
import ChevronLeftIcon from "@mui/icons-material/ChevronLeft";
import ChevronRightIcon from "@mui/icons-material/ChevronRight";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { Outlet, useNavigate, useLocation, Navigate } from "react-router-dom";
import sideBarItems from "./sideBarItems";
import AdminLayoutHeader from "./dashboard/header";
import { useState } from "react";
import { ExpandLess, ExpandMore } from "@mui/icons-material";
import ImageAssets from "assets/images";

const drawerWidth = 240;
interface SubmenuOpenState {
  [key: string]: boolean;
}

const openedMixin = (theme: Theme): CSSObject => ({
  width: drawerWidth,
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: "hidden",
});

const closedMixin = (theme: Theme): CSSObject => ({
  transition: theme.transitions.create("width", {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: "hidden",
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up("sm")]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "flex-end",
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

interface AppBarProps extends MuiAppBarProps {
  open?: boolean;
}

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== "open",
})<AppBarProps>(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  boxShadow: "none",
  transition: theme.transitions.create(["width", "margin"], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(["width", "margin"], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, {
  shouldForwardProp: (prop) => prop !== "open",
})(({ theme, open }) => ({
  width: drawerWidth,
  flexShrink: 0,
  whiteSpace: "nowrap",
  boxSizing: "border-box",
  ...(open && {
    ...openedMixin(theme),
    "& .MuiDrawer-paper": openedMixin(theme),
  }),
  ...(!open && {
    ...closedMixin(theme),
    "& .MuiDrawer-paper": closedMixin(theme),
  }),
}));

export default function AdminLayout() {
  const theme = useTheme();
  let navigate = useNavigate();
  const location = useLocation();
  console.log("location", location.pathname);
  const [open, setOpen] = React.useState(true);
  const [submenuOpen, setSubmenuOpen] = useState<any>({});
  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  // const user = sessionStorage.getItem("USER");
  // if (!user) {
  //   console.log("Redirect to login ", location);
  //   return <Navigate to="/login" state={{ from: location }} replace />;
  // }

  // Function to toggle the submenu open/close state
  const toggleSubmenu = (item: any) => {
    setSubmenuOpen((prevOpen: any) => ({
      ...prevOpen,
      [item.name]: !prevOpen[item.name],
    }));
  };

  return (
    <Box sx={{ display: "flex" }} data-testid="AdminDashboard">
      <CssBaseline />
      <AppBar
        position="fixed"
        open={open}
        color={"secondary"}
        sx={{ backgroundColor: "#000", borderBottom: "1px solid #d3d3d3" }}
      >
        <Toolbar>
          <IconButton
            color="primary"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            edge="start"
            sx={{
              marginRight: 5,
              ...(open && { display: "none" }),
            }}
          >
            <MenuIcon />
          </IconButton>
          <AdminLayoutHeader />
        </Toolbar>
      </AppBar>
      <Drawer variant="permanent" open={open}>
        <DrawerHeader style={{ backgroundColor: "black" }}>
          <img
            src={ImageAssets.verizon}
            alt="logoimage"
            className="sidebar-logo"
          />
          {/* <IconButton onClick={handleDrawerClose}>
            {theme.direction === "rtl" ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton> */}
        </DrawerHeader>
        <Divider />
        <List>
          {sideBarItems.map((val, index) => (
            <React.Fragment key={val.name}>
              <ListItem
                key={`val + ${index}`}
                disablePadding
                sx={{ display: "block" }}
              >
                <ListItemButton
                  sx={{
                    minHeight: 48,
                    justifyContent: open ? "initial" : "center",
                    px: 2.5,
                  }}
                  selected={`/admin/${val.url}` === location.pathname}
                  onClick={() => {
                    navigate(`/${val.url}`);
                    // if (val.submenu) {
                    //   // Toggle the submenu open/close state
                    //   toggleSubmenu(val);
                    // } else {
                    //   navigate(`/${val.url}`);
                    // }
                  }}
                >
                  <ListItemIcon
                    sx={{
                      minWidth: 0,
                      mr: open ? 3 : "auto",
                      justifyContent: "center",
                    }}
                  >
                    <val.icon />
                  </ListItemIcon>
                  <ListItemText
                    primary={val.name}
                    sx={{ opacity: open ? 1 : 0 }}
                  />
                  {/* {val.submenu && (submenuOpen[val.name] ? <ExpandLess /> : <ExpandMore />)} */}
                </ListItemButton>
              </ListItem>
              {/* Render the submenu if it is open */}
              {/* {val.submenu && submenuOpen[val.name] && (
                <Collapse in={submenuOpen[val.name]} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {val.submenu.map((subItem, subIndex) => (
                      <ListItem
                        key={`subItem + ${subIndex}`}
                        disablePadding
                        sx={{ display: "block", paddingLeft: theme.spacing(4) }}
                      >
                        <ListItemButton
                          sx={{
                            minHeight: 48,
                            justifyContent: open ? "initial" : "center",
                            px: 2.5,
                          }}
                          selected={`/admin/${subItem.url}` === location.pathname}
                          onClick={() => {
                            navigate(`/admin/${subItem.url}`);
                          }}
                        >
                          <ListItemIcon
                            sx={{
                              minWidth: 0,
                              mr: 1,
                              justifyContent: "center",
                            }}
                          >
                            <val.icon />
                          </ListItemIcon>
                          <ListItemText primary={subItem.name} />
                        </ListItemButton>
                      </ListItem>
                    ))}
                  </List>
                </Collapse>
              )} */}
            </React.Fragment>
          ))}
        </List>
      </Drawer>
      <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
        <DrawerHeader />
        <Outlet />
      </Box>
    </Box>
  );
}
