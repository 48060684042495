import React, { useState, ChangeEvent, useEffect } from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Divider,
  Box,
  Paper,
  Grid,
  TextField,
  MenuItem,
  Alert,
  Container,
} from "@mui/material";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import TravelExploreIcon from "@mui/icons-material/TravelExplore";
import ClearIcon from "@mui/icons-material/Clear";
import apiService from "services/apiService";

const seasons = ["SPRING", "SUMMER", "AUTUMN", "WINTER"];
const daytimes = ["MORNING", "NOON", "EVENING", "NIGHT"];

const UploadImage = () => {
  const [selectedFiles, setSelectedFiles] = useState<FileList | null>(null);
  const [previews, setPreviews] = useState<string[]>([]);
  const [zipcode, setZipcode] = useState("");
  const [selectedSeason, setSelectedSeason] = useState("");
  const [selectedDaytime, setSelectedDaytime] = useState("");
  const [src, setSrc] = useState("");
  const [show, setShow] = useState(false);

  const handleZipcodeChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setZipcode(event.target.value);
  };

  const handleSeasonChange = (event: React.ChangeEvent<{ value: unknown }>) => {
    setSelectedSeason(event.target.value as string);
  };

  const handleDaytimeChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setSelectedDaytime(event.target.value as string);
  };

  const handleFileChange = (event: ChangeEvent<HTMLInputElement>) => {
    const files = event.target.files;
    setSelectedFiles(files);
  };
  function uploadFileToS3(file: any, s3Url: any) {
    console.log("file.type", file.type);
    fetch(s3Url, {
      method: "PUT",
      headers: {
        "Content-Type": file.type,
      },
      body: file,
    })
      .then((res) => {
        if (!res.ok) {
          throw new Error("Failed to upload file to S3");
        }
        console.log("res s3 success :>> ", res);
      })
      .catch((error) => {
        console.error("Error uploading file to S3:", error);
      });
  }
  useEffect(() => {
    if (selectedFiles) {
      const filePreviews: string[] = [];

      for (let i = 0; i < selectedFiles.length; i++) {
        const file = selectedFiles[i];
        const reader = new FileReader();
        console.log("reader", file);
        reader.onload = (e) => {
          if (e.target) {
            filePreviews.push(e.target.result as string);
            setPreviews([...filePreviews]);
          }
        };

        reader.readAsDataURL(file);
      }
      const data = selectedFiles[0];

      const file = {
        fileName: data.name,
        size: data.size,
        mimeType: data.type,
      };
      // console.log("file", file);
      apiService
        .post("/api/weather/image/upload/url/request", file)
        .then((res) => {
          console.log("res :>> ", res.data);
          uploadFileToS3(data, res.data.uploadUrl);
          setSrc(res.data.srcFilePath);
        })
        .catch((err) => {
          console.log("err :>>", err);
        });
    }
  }, [selectedFiles]);

  const handleUpload = () => {
    if (selectedFiles) {
      console.log(selectedFiles);
    }
  };
  const handleSubmit = () => {
    const data = {
      season: selectedSeason,
      daytime: selectedDaytime,
      s3FilePath: src,
    };
    console.log("data", data);
    apiService
      .put(`/api/weather/image/${zipcode}`, data)
      .then((res) => {
        console.log("res gen:>> ", res.data);
        setShow(true);
      })
      .catch((err) => {
        console.log("err :>>", err);
      });
  };
  useEffect(() => {
    if (show) {
      const timer = setTimeout(() => {
        setShow(false);
      }, 4000);
    }
  }, [show]);

  return (
    <>
      {show && <Alert severity="success">Image Uploaded Successfully</Alert>}
      <Container maxWidth="lg">
        {/* <Card>
          <CardContent> */}
        <Typography variant="h6"> Upload Image</Typography>
        {/* <Divider style={{ margin: "8px 0" }} /> */}
        {/* <Paper sx={{ p: 4 }} elevation={3}> */}
        <Grid container spacing={2}>
          <Grid item xs={6}>
            <TextField
              label="Zipcode"
              variant="outlined"
              fullWidth
              value={zipcode}
              onChange={handleZipcodeChange}
              style={{ margin: "8px 0" }}
            />
            <TextField
              select
              label="Select Season"
              variant="outlined"
              fullWidth
              value={selectedSeason}
              onChange={handleSeasonChange}
              style={{ margin: "8px 0" }}
            >
              {seasons.map((season) => (
                <MenuItem key={season} value={season}>
                  {season}
                </MenuItem>
              ))}
            </TextField>
            <TextField
              select
              label="Select Daytime"
              variant="outlined"
              fullWidth
              value={selectedDaytime}
              onChange={handleDaytimeChange}
              style={{ margin: "8px 0" }}
            >
              {daytimes.map((daytime) => (
                <MenuItem key={daytime} value={daytime}>
                  {daytime}
                </MenuItem>
              ))}
            </TextField>
            <input
              type="file"
              id="file-input"
              style={{ display: "none" }}
              onChange={handleFileChange}
            />
            <Box sx={{ display: "flex", padding: "10px", margin: "10px" }}>
              <label htmlFor="file-input">
                <Button
                  variant="outlined"
                  color="primary"
                  component="span"
                  startIcon={<TravelExploreIcon />}
                >
                  Browse Files
                </Button>
              </label>

              {selectedFiles && (
                <Button
                  variant="outlined"
                  color="primary"
                  onClick={handleSubmit}
                  startIcon={<CloudUploadIcon />}
                  sx={{ marginLeft: "10px" }}
                >
                  Upload
                </Button>
              )}
            </Box>
          </Grid>
          <Grid item xs={6}>
            <Box display="flex" flexDirection="column" alignItems="center">
              {previews.map((preview, index) => (
                <img
                  src={preview}
                  alt={`Preview ${index}`}
                  style={{ width: "512px", height: "512px" }}
                />
              ))}
            </Box>
          </Grid>
        </Grid>
        {selectedFiles && (
          <Grid container spacing={2} justifyContent="flex-end">
            <Grid item>
              <Button
                variant="outlined"
                color="secondary"
                onClick={() => {
                  setSelectedFiles(null);
                  setPreviews([]);
                }}
                startIcon={<ClearIcon />}
              >
                Reset
              </Button>
            </Grid>
          </Grid>
        )}
        {/* </Paper> */}
        {/* </CardContent>
        </Card> */}
      </Container>
    </>
  );
};

export default UploadImage;
