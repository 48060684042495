import * as React from "react";
import { Box, CircularProgress } from "@mui/material";

const Loading = ({ message }: { message: string }) => {
  return (
    <>
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
          p: 1,
          m: 1,
        }}
      >
        <p>
          <CircularProgress /> {message}
        </p>
      </Box>
    </>
  );
};

export default Loading;
