import React, { useState, useEffect } from "react";
import { zipCodeItem } from "services/zipcode/zipcodeIntreface";
import zipcodeServiceManager from "services/zipcode/zipcodeServiceManager";
const UseZipCodeInfo = (zipcode: string) => {
  const [zipCodeInfo, setZipCodeInfo] = React.useState<zipCodeItem>(
    {} as zipCodeItem
  );

  useEffect(() => {
    zipcodeServiceManager.getZipCode(zipcode).then(
      (res: any) => {
        setZipCodeInfo(res.data);
      },
      (err) => {}
    );
  }, [zipcode]);

  return { zipCodeInfo, setZipCodeInfo };
};

export default UseZipCodeInfo;
