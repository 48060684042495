import { AxiosResponse } from "axios";
import { ImageItem, ImageResponse } from "./galleryIntreface";
import apiService from "services/apiService";
import { ZoomIn } from "@mui/icons-material";

class ImageServiceManager {
  private url: string;
  constructor() {
    this.url = `/api/weather`;
  }

  async getImageList(): Promise<AxiosResponse<ImageResponse>> {
    return await apiService.get(`${this.url}/all`);
  }

  async getImageListByZipCode(
    zipcode: string
  ): Promise<AxiosResponse<ImageResponse>> {
    return await apiService.get(`${this.url}/image/${zipcode}`);
  }

  async generateImages(data: {
    zipcode: string;
    season: string;
    daytime: string;
  }): Promise<AxiosResponse<ImageResponse>> {
    return await apiService.post(`${this.url}/image/generate`, data);
  }

  async reGnerateImage(data: {
    zipcode: string;
    season: string;
    daytime: string;
  }): Promise<AxiosResponse<ImageResponse>> {
    return await apiService.post(`${this.url}/image/regenerate`, data);
  }

  // async generateImageByZipCode(zipcode: string): Promise<AxiosResponse<any>> {
  //   return await apiService.get(`${this.url}/image/zipcodes/${zipcode}`);
  // }
  async addZipCode(formData: FormData): Promise<AxiosResponse<ImageItem>> {
    return await apiService.post(this.url, formData);
  }

  async updateZipCode(formData: FormData): Promise<AxiosResponse<ImageItem>> {
    return await apiService.put(this.url, formData);
  }

  async deleteZipCode(id: number): Promise<AxiosResponse> {
    return await apiService.delete(`${this.url}/${id}`);
  }
}

const imageServiceManager = new ImageServiceManager();
export default imageServiceManager;
