import React, { useEffect, useState } from "react";
import { TextField, Button, Container, Box } from "@mui/material";
import logo from "../verizon.png"; // Import your logo image
import { storageService } from "utility/StorageService";
import { useLocation, useNavigate } from "react-router-dom";
function LoginForm() {
  const navigate = useNavigate();
  const { search } = useLocation();
  const checkUserLoggedIn = () => {
    const token = storageService.getItem("accessToken");
    console.log("token :>> ", token);
    if (token) {
      console.log("token1 :>> ", token);
      navigate("/zipcodes");
    } else {
      // storageService.setItem("accessToken", "123");
      const token = new URLSearchParams(search).get("accessToken");
      if (token) {
        console.log("Login Page:User logedin !token value : ", search, token);
        storageService.setItem("accessToken", token);
        navigate("/");
      } else {
        console.log("user is not logged in ", token);
      }
    }
  };

  useEffect(() => {
    checkUserLoggedIn();
  }, []);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const handleUsernameChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setUsername(event.target.value);
  };

  const handlePasswordChange = (event: {
    target: { value: React.SetStateAction<string> };
  }) => {
    setPassword(event.target.value);
  };

  const handleSubmit = (event: { preventDefault: () => void }) => {
    event.preventDefault();
    // Add your login logic here
    console.log("Username:", username);
    console.log("Password:", password);
    storageService.setItem("accessToken", "sample");
    navigate("/zipcodes");
  };

  const handleReset = () => {
    setUsername("");
    setPassword("");
  };

  return (
    <>
      <Container
        maxWidth="sm"
        sx={{ margin: "100px auto", textAlign: "center" }}
      >
        <img
          src={logo}
          alt="Logo"
          style={{ width: "100%", marginBottom: "20px" }}
        />
        <form onSubmit={handleSubmit}>
          <TextField
            id="username"
            label="Username"
            type="text"
            value={username}
            onChange={handleUsernameChange}
            fullWidth
            margin="normal"
          />
          <TextField
            id="password"
            label="Password"
            type="password"
            value={password}
            onChange={handlePasswordChange}
            fullWidth
            margin="normal"
          />
          <Box sx={{ display: "flex", justifyContent: "right", gap: "10px" }}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              size="large"
            >
              Login
            </Button>
            <Button
              variant="contained"
              color="error"
              size="large"
              onClick={handleReset}
            >
              Reset
            </Button>
          </Box>
        </form>
      </Container>
    </>
  );
}

export default LoginForm;
