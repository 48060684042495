import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import ImageGallery from "components/ImageGallery";
import { useParams } from "react-router-dom";
export default function ImageGalleryPage(props: any) {
  // const { name, age } = state;
  const { zipcode } = useParams<{ zipcode: string }>();
  return (
    <>
      <Box>{zipcode && <ImageGallery zipcode={zipcode} />}</Box>
    </>
  );
}
