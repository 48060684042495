import React, { useState, useEffect } from "react";
import imageServiceManager from "services/gallery/ImageServiceManager";

const UseImageSearchByZipCode = (zipcode: string) => {
  const [data, setData] = React.useState<any>([]);
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [isImages, setIsImages] = React.useState<boolean>(true);

  useEffect(() => {
    try {
      imageServiceManager.getImageListByZipCode(zipcode).then(
        (res) => {
          console.log(res);
          if (res.data && res.data.images) {
            setData(res.data.images);
            setIsLoading(false);
            setIsImages(true);
          } else {
            setIsImages(false);
            setIsLoading(false);
          }
        },
        (err) => {
          console.log(err);
          setIsImages(false);
          setIsLoading(false);
        }
      );
    } catch (error) {
      console.error(error);
      setIsImages(false);
    }
  }, [zipcode]);
  return { data, setData, isLoading, setIsLoading, isImages, setIsImages };
};

export default UseImageSearchByZipCode;
